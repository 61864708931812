export default {
  metaInfo () {
    return {
      title: this.$route.meta.title,
      titleTemplate: '%s | ЕДИНАЯ СРЕДА',
      meta: [
        { name: 'description', content: 'ЕДИНАЯ СРЕДА' }
      ]
    }
  }
}
//доки https://vue-meta.nuxtjs.org/api/#meta
