<template>
  <BaseDialog
    v-model="value"
    :max-width="width"
  >
    <template #content>
      <v-progress-linear
        rounded
        height="12"
        :color="color"
        indeterminate
      ></v-progress-linear>
    </template>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'PreloaderWindow',
  components: { BaseDialog },
  props: {
    width: {
      default: '80vw',
      type: String
    },
    value: Boolean,
    color: {
      default: 'green'
    }
  },
  data () {
    return {
      dialog: true
    }
  }
}
</script>
