<template>
  <div class="mobile-wrapper">
    <router-view/>
    <BaseSnackList/>
    <v-progress-linear
      rounded
      height="5"
      class="progress"
      indeterminate
      color="blue"
      v-if="isLoading"
    >
    </v-progress-linear>
  </div>
</template>

<script>
import BaseSnackList from '@/components/base/BaseSnackList/BaseSnackList'
import meta from '@/components/mixins/meta'
import encrypt from '@/components/mixins/encrypt'
import { readSystemFile } from '@/components/utils/mobile/filesystemInteraction'
import { isOffline } from '@/components/utils/mobile/common'
import PreloaderWindow from '@/components/base/UI/PreloaderWindow'

export default {
  mixins: [meta, encrypt],
  name: 'MobileLayout',
  components: { BaseSnackList, PreloaderWindow }, // возможно надо будет переделать под MobileSnakeList
  computed: {
    jwt () {
      return this.$store.getters['auth/getJwt']
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  watch: {
    jwt: {
      async handler () {
        if (this.$store.getters['auth/getJwt']) { //запросы отработают, если мы авторизовались
          this.isLoading = true
          await this.$store.dispatch('region/fetch')
          await this.$store.dispatch('handbook/fetch')
          await this.$store.dispatch('serviceData/fetch')
          this.isLoading = false
        }
      },
      immediate: true
    }
  },
  async mounted () {
    if (JSON.parse(await readSystemFile('profile/l')) && !(await isOffline())) {
      const encryptedLogin = JSON.parse(await readSystemFile('profile/l'))
      const encryptedPassword = JSON.parse(await readSystemFile('profile/p'))
      const decryptedLogin = await this.decrypt(encryptedLogin)
      const decryptedPassword = await this.decrypt(encryptedPassword)
      await this.$store.dispatch('auth/login', { data: { email: decryptedLogin, password: decryptedPassword } })
    }
  },
  beforeMount () {
    import('@/css/mobileLayout.css')
    import('@/css/rewriteStyles.css')
    //подключаю стили так, потому что они переписывают стили для версии пк
  }
}
</script>

<style scoped>
  .mobile-wrapper {
    height: 100%;
  }
  .progress {
    z-index: 9999999;
    position: absolute;
    bottom: 0;
  }
</style>