<template>
  <div>
    <BaseSnack
      v-for="(message, index) in messages"
      :key="index"
      :message="message"
      :message-index="index"
    />
  </div>
</template>

<script>
import BaseSnack from '@/components/base/BaseSnackList/BaseSnack'

export default {
  name: 'BaseSnackList',
  components: { BaseSnack },
  computed: {
    messages () {
      return this.$store.getters['systemMessages/getMessages']
    }
  }
}
</script>

<style scoped>

</style>
