<template>
  <v-snackbar
    v-model="localShow"
    :timeout="message.timeout"
    :color="message.type"
    bottom
    transition="scale-transition"
  >
    <v-icon  class="mr-2">{{message.icon}}</v-icon>
   {{text}}

    <template v-slot:action="{ attrs }">
      <v-btn
        small
        :color="message.type + ' darken-1'"
        @click="hideMessage"
        class="mr-3 ml-3"
      >{{$t('base.ok')}}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'BaseSnack',
  props: {
    message: Object,
    messageIndex: Number
  },
  data () {
    return {
      localShow: true
    }
  },
  computed: {
    text () {
      return this.message.text
    }
  },
  watch: {
    localShow () {
      this.hideMessage()
    }
  },
  methods: {
    hideMessage () {
      this.$store.dispatch('systemMessages/hideMessage', this.messageIndex)
    }
  }
}
</script>

<style scoped>

</style>
